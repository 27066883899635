<template>
	<lidaPage :columns="tableHead" :queryInfo="{ isDistributionInfo: 2 }" :query="customByDis"
		:formColumns="formColumns" :showResetBtn="false" subTitle="总客户数">
		<template #startBusinessTime="scope">
			<div>{{ (scope.row.startBusinessTime || '') + '-' + (scope.row.endBusinessTime || '') }}</div>
		</template>
		<template #enterpriseStatus="scope">
			<div>{{ scope.row.enterpriseStatus === '1' ? '禁用' : '启用' }}</div>
		</template>
		<template #annualTurnover="scope">
			<div>￥{{ scope.row.annualTurnover }}</div>
		</template>
		<!-- <template #operation>
			<lida-table-column label="操作" width="50" fixed="right">
				<template #default="scope">
					<el-button @click="commands(scope.row)" type="text">查看</el-button>
				</template>
			</lida-table-column>
		</template> -->
	</lidaPage>
</template>
<script>
import {
	mapActions
} from 'vuex'
export default {
	data() {
		return {
			tableHead: [ // 表格头部
				{ label: '分销时间', prop: 'createdTime', width: '200' },
				{ label: '公司名称', prop: 'enterpriseName', width: '300' },
				{ label: '客户名称', prop: 'username', width: '200' },
				{ label: '联系方式', prop: 'mobilePhone', width: '300' },
				{ label: '分销公司', prop: 'distributionCompany', width: '300' },
				{ label: '分销人', prop: 'distributionName', width: '200' },
				{ label: '分销联系方式', prop: 'distributionPhone', width: '200' },

			],
			formColumns: [
				{
					type: 'input',
					prop: 'wd',
					label: '综合搜索',
					placeholder: '请输入名字、联系人、法人等关键字'
				},
				{
					type: "select",
					prop: "distributionId",
					label: "分销员筛选",
					options: [],
				},
			],
		}
	},
	created() {
		this.getDistributionList()
	},
	methods: {
		...mapActions('distributionMudule', [
			'customByDis', //我的客户页面
		]),
		...mapActions("myDistributionMudule", [
			"selectDistributionList"
		]),
		getDistributionList() {
			this.selectDistributionList().then((res) => {
				const { data, code } = res.data
				if (code == "0") {
					this.formColumns[1].options = data
				}
			});
		},
		commands(row) { //操作的点击事件
			this.$router.push({ //跳转到详情页
				path: '/client/enterpriseDetails',
				query: {
					id: row.id,
				}
			})
		},
	},
};
</script>
<style lang="less" scoped>
.line {
	background: #F2F5FA;
	height: 10px;
	width: calc(100% + 20px);
	position: relative;
	left: -10px;
}
</style>
